import { useContext }      from "react";
import React, { useState } from "react";
import { useParams }       from "react-router-dom";
import { useNavigate }     from "react-router-dom";
import { useWizard }       from "../../../hooks/useWizard";
import { FlowContext }     from "../../Flow/FlowContext";
import { Header }          from "../../Header";

const CongratsPage = () => {
  const [state, next] = useWizard();
  const context = useContext(FlowContext)
  const { id } = useParams();
  const [type, setType] = useState("");

  const handleClick = (type: string) => {
    setType(type);
    if (type === "game") {
      window.location.href = `/ar-games?productId=${id}&age=${state.age}&clinicName=${context.user.company}`;
    } else if (type === "video") {
      next("vr-videos");
    }
  };

  return (
    <main className="w-content">
      <Header title="Congratulations 🏆"
              description="During your stay in the waiting room, let’s play  a game and then jump to video selection"/>
      <div className="mx-auto my-2 flex flex-col justify-center items-center w-fit	gap-2 flex-1 h-0">
        <div className="w-full" style={{ height: "50%" }}>
          <p className="text-center text-gray-100 text-base font-bold">1. AR GAMES</p>
          <img style={{ height: "70%" }} src="/AR_games.png" alt="AR games"
               onClick={() => handleClick("game")}
               className={`h-full m-auto rounded-13 border-3 border-solid ${type === "game" ? "border-blue-primary " : "border-white"}`}/>
        </div>
        <div className="w-full" style={{ height: "50%" }}>
          <p className="text-center text-gray-100 text-base font-bold">2. Select VR video</p>
          <img style={{ height: "70%" }}
               src="/VR_video.png" alt="VR video" onClick={() => handleClick("video")}
               className={`h-full m-auto rounded-13  border-3 border-solid ${type === "video" ? "border-blue-primary" : "border-white"}`}/>
        </div>
      </div>
    </main>
  );
};

export default CongratsPage;
