import React      from "react";
import { Button } from "../Button";

interface MessageProps{
  icon:{
    src: string
    alt: string
  }
  title?: string
  description: string
  btnText?:string
  onBtnCLick: () => void
}
export const Message = React.memo<MessageProps>(({icon,title,description,btnText="Got it!",onBtnCLick}) => {
  return(
    <div className="flex flex-col justify-center items-center">
      <img src={icon.src} alt={icon.alt} />
      <p className="text-1.5 font-bold text-center">{title}</p>
      <p className="text-lg	 leading-6 text-center mt-2.5 mb-11">{description}</p>
      <Button text={btnText} type="button" onClick={onBtnCLick}/>
    </div>
  )
})