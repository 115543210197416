import React                           from "react";
import { Navigate, Outlet, useParams } from "react-router-dom";
import api                             from "../../Api";
import { useAsync }                    from "../../hooks/useAsync";
import { useWizard }                   from "../../hooks/useWizard";
import { routes }                      from "../../routes";
import { Loader }                      from "../Loader";
import NotFound                        from "../NotFound";
import { FlowContext }                 from "./FlowContext";

export const Flow = React.memo(function Flow() {
  const params = useParams();
  const { data, error, loading } = useAsync(() => api.getProduct(params.id), [params.id]);
  if (loading) {
    return <Loader/>;
  }
  if ((!data || !!error)) {
    return <NotFound/>;
  }
  return <FlowContext.Provider value={data}><ProductFlow/></FlowContext.Provider>;
});
export const ProductFlow = React.memo(() => {
  const params = useParams();
  const [state] = useWizard();
  const path = params[ "*" ];
  const index = routes.findIndex(r => r.path === path);
  const route = routes[ index ];
  const requires: string[] = route?.requires;
  if (!route || requires.every(k => !!state[ k ])) {
    return (
      <Outlet/>
    );
  }
  return <Navigate to={routes[ index - 1 ].path} replace/>;
});
