import React              from "react";

const NotFound = () => {

  return (
    <main className="w-content">
      <div className="flex flex-col justify-center items-center h-screen gap-5">
        <img src="/Keppy.svg" alt="Keppy"/>
        <img src="/crying_icon.svg" alt="crying icon"/>
        <p className="text-center">
          <span className="text-lg">We apologize, but the requested link could not be found.</span>
        </p>
      </div>
    </main>
  );
};

export default NotFound;
