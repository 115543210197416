import React from "react";

interface VideoItemProps {
  src?: string,
  title: string,
  channelTitle?: string,
  selected?: boolean
  active?: boolean
}
export const VideoItem = React.memo<VideoItemProps>(({ src, title, channelTitle, selected, active }) => {
  return (
    <div className={`${selected ? "px-8 flex py-4" : ""}`}>
      <div style={selected ? {} : { minHeight: 208 }}
           className={`${selected ? "flex-1 mr-3 my-auto" : active ? "border-2 border-solid border-blue-primary" : "border-2 border-solid border-[#e6eaf200]"}`}>
        <img src={src} alt="" className="w-full"/>
      </div>
      <div className={`${selected ? "flex-[2]" : "py-2 px-2"}`}>
        <p
          className={`font-bold ${selected ? "text-base" : "text-lg"}`}>{selected ? (title.length > 30 ? title.substring(0, 30) + "..." : title) : title}</p>
        <p className="text-base	text-gray-primary font-light">{channelTitle}</p>
      </div>
    </div>
  );
});
