import React              from "react";
import { Button }         from "../../Button";
import { GoToPrevPage }   from "../../GoToPrevPage";
import { Header }         from "../../Header";
import { VideoItem }      from "../../VideoItem";
import {useWizard} from "../../../hooks/useWizard";

const QRCode = () => {
  const [values] = useWizard()
    const {src, title, channelTitle, qr, code} = values.selectedVideo

  return (
    <main className="w-content">
      <GoToPrevPage/>
      <Header title="Show QR code to dentist" description="The selected content will be projected in the Virtual Reality system, via scanning QR code in dentist room"/>
      <div className="py-1 px-4 shadow-xl">
        <VideoItem src={src} title={title} channelTitle={channelTitle} selected/>
      </div>
      <div className="text-center overflow-hidden h-full">
        <img src={qr} alt="QR-code" className="h-[inherit] object-contain m-auto"/>
      </div>
      <div className="text-gray-dark text-2xl text-center ">{`Code:  ${code?.slice(-3)}`}</div>
      <a href={qr} download="QR_Code.png"><Button text="Save"/></a>
    </main>
  );
};

export default QRCode;
