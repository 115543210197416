import React           from "react";
import { Form }        from "react-final-form";
import { useWizard }   from "../../../hooks/useWizard";
import { Button }      from "../../Button";
import { Header }      from "../../Header";
import { Input }       from "../../Input";
import { Steps }       from "../../Steps";

const VirtualReality = () => {
  const [value, next] = useWizard();
  const onSubmit = (values: any) => {
    next("greeting", values);
  };

  const handleKeyPress = (evt: any) => {
    let key = (evt.which) ? evt.which : evt.keyCode;
    if ((key >= 65 && key <= 90) || key===8 || key === 32) {
      return
    }
    else{
      evt.preventDefault()
    }
  };
  return (
    <main className="w-content">
      <Header title="Enjoy Virtual Reality For Dentistry 🚀"
              description="VR is the most powerful tool for patient's distraction, it reliefs pain & stress up to 35%"
              className="pt-6.5"/>
      <div className="flex flex-col justify-center items-center flex-grow-1 gap-2 h-0">
        <img src="/Keppy.svg" alt="Keppy"/>
        <img src="/siting_man.png" alt="Keppy men" className="h-[inherit] content-img" style={{ height: "85%" }}/>
      </div>
      <Form
        onSubmit={onSubmit}
        initialValues={{ name: value.name }}
        validate={(values) => {
          const errors: any = {};
          if (!values.name) {
            errors.name = "Please fill in your name";
          }
          return errors;
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={"flex flex-col gap-2"}>
            <Input type="text" name="name" placeholder="Please enter your name" className="text-center" onKeyPress={handleKeyPress}/>
            <Steps activeStep={value.doctor ? 2 : 1}/>
            <Button text="Start" type="submit"/>
          </form>
        )}
      />
    </main>
  );
};

export default VirtualReality;
