import React           from "react";
import { useNavigate } from "react-router-dom";

interface GoToPrevPageProps {
  text?: string;
}
export const GoToPrevPage = React.memo<GoToPrevPageProps>(({ text }) => {
  const navigate = useNavigate();
  const onClick = (e) => {
    e.preventDefault();
    navigate(-1);
  };
  return (
    <a onClick={onClick} href={"#"}>
      <img src="/arrow-left.svg" alt="Go to prev page" className={`h-5 ${text && "float-left"}`}/>
      {text && <span className="text-lg pl-1 text-gray-100 font-bold">{text}</span>}
    </a>
  );
});
