import React    from "react";
import { Link } from "react-router-dom";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>{
  text: string;
  href?: string;
  target?: string;
}

export const Button = React.memo<ButtonProps>(({text, href,target='self',...rest}) => {
  return (
    <button className="bg-blue-primary text-white py-4 font-bold rounded-full w-full" {...rest}>
      {
        href ? <Link to={href} target={`_${target}`}>{text}</Link> : <span>{text}</span>
      }
    </button>
  )
})
