class Api {
  baseUrl: string;
  constructor() {
    this.baseUrl = process.env.REACT_APP_SERVER_URL || "";
  }

  async request(path: string, options?: RequestInit) {
    const response = await fetch(`${this.baseUrl}/api/v1/${path}`, options);
    if (response.ok) {
      return response.json();
    }
    throw await response.json();
  }
  async verify(data) {
    return this.request("customers/verify", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    });
  }
  async validate({ phoneNumber, code }) {
    return this.request("customers/validate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ phoneNumber, code })
    });
  }
  async resendCode(id: string) {
    return this.request(`customers/${id}/resend`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      }
    });
  }
  async getVideos(productId,age) {
    return this.request(`products/${productId}/videos?where={"age":"${age}"}`);
  }
  async getVideoQR(productId, body) {
    return this.request(`customers/${productId}/procedures/qr`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    });
  }
  async getClinicUsers(clinicId) {
    return this.request(`clinics/${clinicId}/products?include=user`);
  }
  async getProduct(productId) {
    return this.request(`products/${productId}?include=user`);
  }
}

export default new Api();
