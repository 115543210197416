import { useState }     from "react";
import React            from "react";
import { Form }         from "react-final-form";
import { useWizard }    from "../../../hooks/useWizard";
import { Button }       from "../../Button";
import { Gender }       from "../../Gender";
import { GoToPrevPage } from "../../GoToPrevPage";
import { Header }       from "../../Header";
import { SelectBox }    from "../../SelectBox";
import { Steps }        from "../../Steps";

const Greeting = () => {
  const [state, next] = useWizard();
  const [gender, setGender] = useState(state.gender || "female");
  const [checked, setChecked] = useState(false);
  const options = [
    {
      key: "3-5",
      value: "3-5Y"
    },
    {
      key: "6-12",
      value: "6-12Y"
    },
    {
      key: "13-18",
      value: "13-18Y"
    },
    {
      key: "19-35",
      value: "19-35Y"
    },
    {
      key: "36+",
      value: "36Y+"
    },
  ];

  const handleGenderSelected = (gender: string) => {
    setGender(gender);
    setChecked(false);
  };

  const onSubmit = async (values: any) => {
    values.gender = gender;
    next("verifying", values);
  };

  const handleChecked = () => {
    setChecked(true);
    setGender("other");
  };

  return (
    <main className="w-content">
      <GoToPrevPage/>
      <Header title={`Hi, ${state.name}! 👋`}
              description="Please select patient’s age and gender so we can provide you with the best service"/>
      <Form
        onSubmit={onSubmit}
        initialValues={{ gender: state.gender || "female", age: state.age }}
        validate={(values: any) => {
          const errors: any = {};
          if (!values.age) {
            errors.age = "Please select age";
          }
          return errors;
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} style={{ display: "contents" }}>
            <Gender gender={gender} onSelected={handleGenderSelected} checked={checked} onChecked={handleChecked}/>
            <SelectBox
              name="age"
              options={options}
              label="Select age range"
            />
            <Steps activeStep={state.doctor ? 3 : 2}/>
            <Button text="Next" type="submit"/>
          </form>
        )}
      />
    </main>
  );
};

export default Greeting;
