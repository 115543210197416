import React from 'react';

interface HeaderProps {
  title: string;
  description?: string;
  className?: string;
}

export const Header = React.memo<HeaderProps>(({title, description,className}) => {
  return (
    <>
      <h1 className={`text-lg font-bold text-center ${className ? className : ''}`}>
        {title}
      </h1>
      {description && <p className="text-base text-center">{description}</p>}
    </>
  )
})
