export function getTimeByDuration(duration:string) {
  const regex = /PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/;
  const match:any = duration.match(regex);
  const hours = match[1] ? parseInt(match[1]) : 0;
  const minutes = match[2] ? parseInt(match[2]) : 0;
  const seconds = match[3] ? parseInt(match[3]) : 0;

  return {
    hours,
    minutes,
    seconds,
  }
}

export function extractVideoId(url:string) {
  const regex = /(?:youtu\.be\/|watch\?v=)([^&?]+)/;
  const match = url.match(regex);
  if (match && match[1]) {
    return match[1];
  } else {
    return '';
  }
}