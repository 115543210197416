import React, { useRef } from 'react'
import { createPortal }  from 'react-dom'

interface ModalProps {
  children: React.ReactElement
  onClose: () => any
  title?: string,
  open?: boolean,
  className?: string,
  rootClassName?: string,
  search?: boolean,
  onSearch?: (e:any) => any,
  searchValue?:string,
  closeButton?: boolean
}
export const Modal = React.memo<ModalProps>(({
  children,
  title=null,
  onClose,
  className,
  rootClassName= 'bg-gray-lighter',
  open=false,
  search=false,
  onSearch,
  searchValue,
  closeButton=true
}) => {
  const rootRef:any = useRef()

  if (!open) {
    return null
  }

  return createPortal(
    <div
      ref={rootRef}
      className={`fixed left-0 right-0 top-0 bottom-0 flex flex-row items-center justify-center p-5 ${rootClassName}`}
      data-testid="modal-background"
    >
      <div
        className={`flex flex-col bg-white p-6 max-h-full  max-w-sm w-full ${className}`}
      >
        {title && closeButton && <div className="flex shrink-0 justify-between items-center mb-4">
          <b className="text-xl">{title}</b>
          <span onClick={onClose} className="cursor-pointer">x</span>
        </div>}
        {search &&
        <input
          type="text"
          placeholder="Search"
          value={searchValue}
          className="p-4 border border-solid border-gray-lighter"
          onChange={(e) => onSearch?.(e)}
        />}
        <div
          className="overflow-y-auto max-h-full"
          data-testid="modal-content"
        >
          {children}
        </div>
      </div>
    </div>,
    document.body
  )
})