import { useState }       from "react";
import React              from "react";
import api                from "../../../Api";
import { useLazyAsync }   from "../../../hooks/useAsync";
import { useWizard }      from "../../../hooks/useWizard";
import { Button }         from "../../Button";
import { EnterCode }      from "../../EnterCode";
import { GoToPrevPage }   from "../../GoToPrevPage";
import { Header }         from "../../Header";
import { HelpingMessage } from "../../HelpingMessage";
import { Steps }          from "../../Steps";

export const AlmostDone = React.memo(() => {
  const [error, setError] = useState("");
  const [state, next] = useWizard();
  const [call, { loading }] = useLazyAsync((data) => api.validate(data), []);
  const [verificationCode, setVerificationCode] = useState("");

  const handleCodeSubmit = async (code: any) => {
    setVerificationCode(code);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (verificationCode !== "") {
      const data = await call({
        code: verificationCode,
        phoneNumber: state.phoneNumber
      });
      if (!data) {
        setError("Invalid code");
      } else {
        next("congrats", data);
      }
    } else {
      setError("Please fill the 4-digit number");
    }
  };
  return (
    <main className="w-content">
      <GoToPrevPage/>
      <Header title="Almost done  🔭"
              description={`Check your phone for verification code, we have sent it to ${state.phoneNumber}`}/>
      <div className="flex flex-col justify-center items-center overflow-hidden flex-grow-1 h-0">
        <img src="/keppy_done.png" alt="Keppy men" className="h-[inherit] content-img"/>
      </div>
      <form onSubmit={handleSubmit} className={"flex flex-col gap-2"}>
        <div className="flex flex-col gap-6">
          <EnterCode callback={handleCodeSubmit}/>
        </div>
        {error && <HelpingMessage message={error}/>}
        <Steps activeStep={state.doctor ? 5 : 4}/>
        <Button disabled={loading} text="Verify" type="submit"/>
      </form>
    </main>
  );
});
