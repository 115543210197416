import React                      from 'react';
import { Field }          from "react-final-form";
import { HelpingMessage } from "../HelpingMessage";

interface InputProps {
    type: React.HTMLInputTypeAttribute;
    name: string;
    placeholder: string;
    className? : string;
  onKeyPress?: (evt)=>void
}
export const Input = React.memo<InputProps>((
  {type, name, placeholder, className='text-xl', onKeyPress}
) => {

  const handleKeyPress = (evt: any) => {
   if(onKeyPress){
     onKeyPress(evt)
   }
  };
    return (
        <div className="w-full">
            <Field
              name={name}
              component="input"
              type={type}
            >
                {({ input, meta }) => {
                    return <>
                        <input placeholder={placeholder} {...input} className={`bg-gray-lighter text-gray-primary p-4 rounded-md w-full text-xl ${className}`} onKeyDown={(event)=>{handleKeyPress(event)}}/>
                        {meta.touched && (!!meta.error || (!meta.modifiedSinceLastSubmit && !!meta.submitError)) && <HelpingMessage message={meta.error || meta.submitError}/>}
                    </>;
                }}
            </Field>
        </div>
    )
})
