import { useLayoutEffect } from "react";
import { useState }        from "react";
import { useEffect }       from "react";
import React               from "react";
import { FemaleIcon }      from "./FemaleIcon";
import { MaleIcon }        from "./MaleIcon";

interface GenderProps {
  gender: string;
  onSelected: (type: string) => void;
  checked: boolean;
  onChecked: () => void;
}
export const Gender = React.memo<GenderProps>(({ gender, onSelected, checked, onChecked }) => {
  return (
    <div className="m-0 flex flex-col justify-center items-center flex-1 h-0 overflow-hidden">
      {/*<div className="gender">*/}
      {/*  /!*<img src={female} alt="female" onClick={() => onSelected("female")}*!/*/}
      {/*  /!*     className={`pt-5 px-4 rounded-13 border-3 border-solid h-90 ${gender === "female" ? "border-blue-primary " : "border-[#e6eaf200]"} content-img`}/>*!/*/}
      {/* */}
      {/*</div>*/}
      <FemaleIcon selected={gender === "female"} onClick={() => onSelected("female")} />
      <MaleIcon
        selected={gender === "male"}
        onClick={() => onSelected("male")}
      />
      <div className="py-4 text-gray-primary">
        <div
          className="h-[24px] w-[24px] border-2 border-solid border-rounded rounded-full float-left	mr-3 flex items-center justify-center"
          onClick={onChecked}>
          {
            (checked || gender === "other") &&
            <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 4L5.24515 7.39612C5.66612 7.73289 6.27826 7.67536 6.6291 7.26604L12 1" stroke="#0938DF"
                    strokeWidth="2" strokeLinecap="round"/>
            </svg>
          }
        </div>
        <span className="text-lg font-bold">Preferred not to say</span>
      </div>
    </div>
  );
});
