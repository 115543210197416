import React         from "react";
import { useWizard } from "../../hooks/useWizard";
interface StepsProps {
  activeStep?: number,
  length?: number
}
export const Steps = React.memo<StepsProps>(({ activeStep=1,length }) => {

  const [value] = useWizard();
  const steps = Array.from({ length: (length ? length : (value.doctor ? 5 : 4 ))}, (_, index) => index + 1);

  return (
    <div className="w-full m-auto">
      <div className="mb-4 mt-4 flex w-233 justify-around mx-auto">
      {
        steps.map((step) =>
          <div key={step} className={`h-8 w-42 rounded-xl ${step === activeStep ? 'bg-blue-primary' : 'bg-gray-secondary'}`} />
        )
      }
      </div>
    </div>
  )
})
