import { useReactiveVar }  from "react-reactive-var";
import { makeVar }         from "react-reactive-var";
import { useParams }       from "react-router-dom";
import { useNavigate }     from "react-router-dom";
import { NavigateOptions } from "react-router/dist/lib/context";

const STORAGE_KEY = "keppyvr";

const initialValues = () => {
  try {
    const item = window.localStorage.getItem(STORAGE_KEY);
    if (item) {
      return JSON.parse(item);
    } else {
      return Object.create(null);
    }
  } catch (error) {
    // If error also return initialValue
    console.log(error);
    return Object.create(null);
  }
};

const stateVar = makeVar<Record<string, any>>(initialValues());

export function useWizard(): [State, Next, SetState] {
  const { id } = useParams();
  const navigate = useNavigate();

  const storedValue = useReactiveVar(stateVar);
  const setValue = (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;

      stateVar(valueToStore);
      window.localStorage.setItem(STORAGE_KEY, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  };
  const next: Next = (path: string, data?, options?: NavigateOptions) => {
    setValue(prevData => ({ ...prevData, ...data }));
    setTimeout(() => {
      navigate(`/${id}/${path}`, options);
    });
  };

  return [storedValue, next, setValue];
}

export type Next = (path: string, data?: Record<string, any>,options?: NavigateOptions) => void
export type State = Record<string, any>;
export type SetState = (value: any) => void
