import React              from "react";
import { Field }          from "react-final-form";
import { HelpingMessage } from "../HelpingMessage";

interface SelectBoxProps {
  options: string[] | { key: string, value: string }[];
  label: string;
  name: string;
}


export const SelectBox: React.FC<SelectBoxProps> = (props) => {
  const { options, name, label } = props;
  return (
    <div>
      <Field name={name}>
        {({ input, meta }) => {
          return <>
            <select {...input}
                    className="bg-gray-lighter text-xl text-gray-primary p-4 rounded-md w-full text-center">
              <option value="" disabled>{label}</option>
              {options.map((option) => {
                if (typeof option === "string") {
                  return <option key={option} value={option}>{option}</option>;
                }
                return <option key={option.key} value={option.key}>{option.value}</option>;
              })}
            </select>
            {meta.touched && !!meta.error && <HelpingMessage message={meta.error}/>}
          </>;
        }}
      </Field>

    </div>
  );
};