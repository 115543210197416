import { useMemo }         from "react";
import { useEffect }       from "react";
import { useState }        from "react";
import React               from "react";
import { useLocation }     from "react-router-dom";
import { useParams }       from "react-router-dom";
import api                 from "../../../Api";
import { getYoutubeVideo } from "../../../common/getYoutubeVideo";
import { extractVideoId }  from "../../../common/helper";
import { useLazyAsync }    from "../../../hooks/useAsync";
import { useAsync }        from "../../../hooks/useAsync";
import { useWizard }       from "../../../hooks/useWizard";
import { Button }          from "../../Button";
import { GoToPrevPage }    from "../../GoToPrevPage";
import { Loader }          from "../../Loader";
import { NavBar }          from "../../NavBar";
import { VideoItem }       from "../../VideoItem";

export const VRVideo = () => {
  const [value, next] = useWizard();
  const location = useLocation();
  const selectedLink = location.state?.link;
  const { id } = useParams();
  const [selected, setSelected] = useState(null);
  const [category, setCategory] = useState<string>("all");
  const { data: store, loading: loadingStored } = useAsync(() => api.getVideos(id, value.age), [id, value.age]);
  const [call] = useLazyAsync((data) => api.getVideoQR(value.id, data), []);
  const { data = [], loading: loadingVideos } = useAsync(async () => {
    try {
      if (!loadingStored && Array.isArray(store.results) && store.results.length) {
        const list = store.results.map(d => (
          {
            category: d.category,
            id: extractVideoId(d.url)
          }
        ));
        if(selectedLink){
          list.unshift({
            id: extractVideoId(selectedLink)
          })
        }
        return await Promise.all(
          list.map(async video => (
            {
              category: video.category,
              ...await getYoutubeVideo(video.id)
            }
          ))
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [store, loadingStored, selectedLink]);
  const loading = loadingStored || loadingVideos;
  const items = [
    {
      key: "all",
      categoryId: "all",
      category: "All"
    },
    {
      key: "cartoon",
      categoryId: "Cartoon",
      category: "Cartoon 🧸"
    },
    {
      key: "nature",
      categoryId: "Nature",
      category: "Nature 🌳"
    },
    {
      key: "vr",
      categoryId: "360VR",
      category: "360VR ‍🥽💫"
    },
    {
      key: "game",
      categoryId: "Game",
      category: "Game 🎮"
    },
    {
      key: "other",
      categoryId: "Other",
      category: "Other"
    }
  ];
  const handleSelected = (item) => {
    setSelected(item);
  };
  const handleClick = async () => {
    const data = await call({
      url: `https://www.youtube.com/watch?v=${selected.id}`,
      productId: id
    });
    const { title, channelTitle, orientation } = selected;
    const selectedVideo = {
      title,
      src: orientation.url,
      channelTitle,
      id: selected.id,
      qr: data?.qr,
      code: data?.code
    };
    next("QR-code", { selectedVideo: selectedVideo });
  };
  const handleVideoClick = () => {
    next("add-video", {});
  };
  const handleCategory = (id: string) => {
    setCategory(id);
  };
  const list = useMemo(() => category === "all" ? data : data.filter(d => d.category === category), [data, category]);
  return (
    <main className="w-content">
      <div className="max-h-dvh min-h-dvh flex flex-col">
        <div className="shrink-0">
          <div className="">
            <GoToPrevPage/>
          </div>
          <NavBar navItems={items} onSelected={handleCategory}/>
        </div>
        <div className="grow-1 overflow-y-auto">
          {!loading &&
            <div>
              {
                list.length ? list.map((video: any, index: number) => <div key={index}
                                                                           onClick={() => handleSelected(video)}>
                  <VideoItem src={video.orientation.url} title={video.title} channelTitle={video.channelTitle}
                             active={selected?.id === video.id}/>
                </div>) : <p><span>No content found. You can add your video here.</span></p>
              }
            </div>
          }
        </div>
        <div className="shrink-0">
          {
            selected ? <div className="pb-8">
                <VideoItem src={selected.orientation.url} title={selected.title} channelTitle={selected.channelTitle}
                           selected/>
                <Button text="Select" onClick={handleClick}/>
              </div> : <div className=" pt-6 pb-10">
               <p className="text-l pb-4 text-center">Cannot find what you want? </p>
              <Button  text="Add Your Video" type="button" onClick={handleVideoClick}/>
            </div>
              // <p className="p-6 pb-10 text-xs text-center">Cannot find what you want? <span
              //   className="text-blue-primary font-bold underline"
              //   onClick={handleVideoClick}>Add your video</span></p>
          }
        </div>
      </div>
      {loading && <Loader/>}
    </main>
  );
};
