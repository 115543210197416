import { useCallback }       from "react";
import { useState }          from "react";
import React                 from "react";
import { Form }              from "react-final-form";
import { getYoutubeVideo }   from "../../../common/getYoutubeVideo";
import { extractVideoId }    from "../../../common/helper";
import { getTimeByDuration } from "../../../common/helper";
import { useWizard }         from "../../../hooks/useWizard";
import { Button }            from "../../Button";
import { GoToPrevPage }      from "../../GoToPrevPage";
import { Header }            from "../../Header";
import { Input }             from "../../Input";
import { Message }           from "../../Message";
import { Modal }             from "../../Modal";

export const AddVideo = () => {
  const [inputValue, setInputValue] = useState({});
  const [_, next] = useWizard();
  const [show, setShow] = useState(false);
  const handleOpen = useCallback(() => {
    setShow(true);
  }, []);
  const [message, setMessage] = useState<{
    title?: string,
    description: string,
    icon: any,
    success?: boolean | undefined,
  }>({ description: "", icon: {}, success: false });

  const handleClose = useCallback(() => setShow(false), []);

  const onSubmit = async (values: any) => {
    try {
      const videoId = extractVideoId(values.link);
      const video: any = await getYoutubeVideo(videoId);
      const time = getTimeByDuration(video.duration);
      const quality = video.definition;
      if (time.minutes < 30 && time.hours === 0) {
        handleOpen();
        setMessage({
          description: "The selected content duration is less than 30 min.",
          icon: {
            src: "/30m_icon.png",
            alt: "30m icon"
          }
        });
      } else if (video.orientation.width < video.orientation.height) {
        handleOpen();
        setMessage({
          description: "The selected content should be in landscape mode only.",
          icon: {
            src: "/phone_rotate.png",
            alt: "phone rotate icon"
          }
        });
      } else if (quality !== "hd" && quality !== "high") {
        handleOpen();
        setMessage({
          description: "The selected content quality should be higher than 1080p.",
          icon: {
            src: "/1800p_icon.png",
            alt: "1080p icon"
          }
        });
      } else {
        handleOpen();
        setMessage({
          title: "Your video has been added",
          description: "You can see your added videos in the content page",
          icon: {
            src: "/heart_icon.png",
            alt: "heart icon"
          },
          success: true
        });
        setInputValue(values);
      }
    } catch (error) {
      handleOpen();
      setMessage({
        title: "Oops! Something went wrong",
        description: "Could you please double-check the content link and try again",
        icon: {
          src: "/oops_icon.png",
          alt: "Oops icon"
        }
      });
      return null;
    }
  };

  const handleGotIt = (success: boolean | undefined) => {
    handleClose();
    if (success) {
      next("vr-videos", {}, { state: inputValue });
    }
  };

  return (
    <main className="w-content">
      <GoToPrevPage/>
      <Header title="My video"/>
      <div className="flex flex-col justify-center items-center overflow-hidden">
        <img src="/youtube.png" alt="youtube" className="h-[inherit] overflow-hidden"/>
      </div>
      <Form<FormData>
        onSubmit={onSubmit}
        render={({ handleSubmit, values }: any) => (
          <form onSubmit={handleSubmit}>
            <p className="text-2xl font-bold text-center">
              Copy any link from YouTube
            </p>
            <p className="text-xl leading-6 text-center mt-3.5">and paste it here:</p>
            <Input type="text" name="link" placeholder="Link" className="text-center my-6"/>
            <p className="text-base	text-gray-primary text-center mb-6">Note, the content requirements are: Duration 30
              min. ,
              Landscape mode, Quality 1080p.</p>
            {values.link ? <Button text="Add" type="submit"/> :
              <Button text="Go to YouTube" type="button" target="blank" href="https://www.youtube.com/"/>}
          </form>
        )}
      />
      <Modal closeButton={false} open={show} onClose={handleClose}
             rootClassName="bg-[rgba(255, 255, 255, 0.2)] backdrop-blur-sm" className="rounded-10 shadow-xl">
        {message && <Message
          title={message.title}
          description={message.description}
          icon={message.icon}
          onBtnCLick={() => handleGotIt(message.success)}/>}
      </Modal>
    </main>
  );
};
