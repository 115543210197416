import { useState }                 from "react";
import React                        from "react";
import { Field }                    from "react-final-form";
import { Form }                     from "react-final-form";
import api                          from "../../../Api";
import { useLazyAsync }             from "../../../hooks/useAsync";
import { useWizard }                from "../../../hooks/useWizard";
import { Button }                   from "../../Button";
import CountrySelectorWithDetection from "../../CountrySelectorWithDetection/CountrySelectorWithDetection";
import { GoToPrevPage }             from "../../GoToPrevPage";
import { Header }                   from "../../Header";
import { Steps }                    from "../../Steps";
import countries                    from "../../CountrySelectorWithDetection/countries.json";

const Verifying = () => {
  const [state, next] = useWizard();
  const [call] = useLazyAsync((data) => api.verify(data), []);
  const [selectedCountry, setSelectedCountry] = useState<any>({
    "name": "United States",
    "dialCode": "+1",
    "isoCode": "US",
    "flag": "https://cdn.kcak11.com/CountryFlags/countries/us.svg"
  });
  const [mobileNumber, setMobileNumber] = useState("");

  const handleCountrySelect = (country: any) => {
    setMobileNumber(mobileNumber);
    setSelectedCountry(country);
  };

  const onSubmit = async (values: any) => {
    const data = await call({
      ...state,
      phoneNumber: `${selectedCountry.dialCode}${values.phoneNumber}`
    });
    if (!data) {
      return { phoneNumber: "Invalid phone number" };
    }
    if (data.verified) {
      next("congrats", data);
    } else {
      next("verification-code", data);
    }
  };
  return (
    <main className="w-content">
      <GoToPrevPage/>
      <Header title="Let’s verify you  🔎"
              description="Enter your phone number to verify yourself and confirm that you are not a robot "/>
      <div className="flex flex-col justify-center items-center overflow-hidden flex-grow-1 h-0">
        <img src="/keppy_verify.png" height={"100%"} alt="Keppy men" className="h-[inherit] content-img"/>
      </div>
      <Form<FormData>
        onSubmit={onSubmit}
        validate={(values: any) => {
          const errors: any = {};
          if (!values.phoneNumber) {
            errors.phoneNumber = "Please fill in the phone number";
          }
          return errors;
        }}
        render={({ handleSubmit, submitting }: any) => (
          <form onSubmit={handleSubmit} className={"flex flex-col gap-2"}>
            <Field name={"agree"}>
              {({ input }) => (
                <div className="py-4 flex text-gray-primary">
                  <div style={{ marginLeft: "16px", paddingTop: "7px", marginRight: "35px" }}>
                    <div
                      className="h-[24px] w-[24px] border-2 border-solid border-rounded border-[#0938DF] rounded-full float-left	mr-3 flex items-center justify-center"
                      onClick={()=>input.onChange(!input.value)}
                    >
                      {!!input.value &&
                        <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 4L5.24515 7.39612C5.66612 7.73289 6.27826 7.67536 6.6291 7.26604L12 1"
                                stroke="#0938DF"
                                strokeWidth="2" strokeLinecap="round"/>
                        </svg>
                      }
                    </div>
                  </div>
                  <span className="text-base" style={{ fontSize: 11 }}>
                  <span>I am aware that the provided phone number will be used for post-procedure communication.</span>
                  <br/>
                  <div className={"mt-2"}>I understand that I may receive messages from the current dental practice.</div>
                  <div className={"mt-2"}>SMS messaging fees may apply and you can respond with "STOP" at any time to prevent further contact.</div>
              </span>
                </div>
              )}
            </Field>

            <CountrySelectorWithDetection countries={countries} onSelect={handleCountrySelect}/>
            <Steps activeStep={state.doctor ? 4 : 3}/>
            <Field name={"agree"} subscription={{value:true}}>
              {({input})=><Button disabled={submitting || !input.value} text="Send code" type="submit"/>}
            </Field>

          </form>
        )}
      />
    </main>
  );
};

export default Verifying;
