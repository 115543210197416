import { AddVideo }   from "./components/Page/AddVideo";
import { AlmostDone } from "./components/Page/AlmostDone";
import { ARGames }    from "./components/Page/ARGames";
import Greeting       from "./components/Page/Greeting";
import QRCode         from "./components/Page/QRCode";
import Verifying      from "./components/Page/Verifying";
import VirtualReality from "./components/Page/VirtualReality";
import { VRVideo }    from "./components/Page/VRVideo";
import CongratsPage   from "./components/Page/WaitingPage";

export const routes = [
  {
    path: "start",
    requires: [],
    component: VirtualReality
  },
  {
    path: "greeting",
    requires: ["name"],
    component: Greeting
  },
  {
    path: "verifying",
    requires: ["name", "age"],
    component: Verifying
  },
  {
    path: "verification-code",
    requires: ["name", "age", "phoneNumber"],
    component: AlmostDone
  },
  {
    path: "congrats",
    requires: ["name", "age", "phoneNumber", "verified"],
    component: CongratsPage
  },
  {
    path: "vr-videos",
    requires: ["name", "age", "phoneNumber", "verified"],
    component: VRVideo
  },
  {
    path: "QR-code",
    requires: ["name", "age", "gender", "phoneNumber", "verified","selectedVideo"],
    component: QRCode
  },
  {
    path: "add-video",
    requires: ["name", "age", "gender", "phoneNumber", "verified"],
    component: AddVideo
  }
];
