import React, { useState }        from "react";

interface NavBarProps {
  navItems: any[];
  onSelected?: (id:string) => void
}

export const NavBar = React.memo<NavBarProps>(({ navItems,onSelected }) => {

  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleSelected = (index:number, id:string) => {
    setSelectedIndex(index)
    if(onSelected) {
      onSelected(id)
    }
  }

  return (
    <nav className="pt-4 pb-2">
      <div className="w-full w-auto" id="navbar-default">
        <ul className="font-medium flex overflow-x-scroll	pb-2">
          {navItems.map((items, index) => (
            <li
              key={items.key}
              className={`border border-solid rounded-full min-w-120 mx-2 first:ml-0 last:mr-0 text-center py-2 ${selectedIndex === index ? 'bg-blue-primary text-white border-blue-primary' : ''}`}
              onClick={() => handleSelected(index, items.categoryId)}
            >
              <a
                className="font-bold"
                onClick={event => event.preventDefault()}
                href="#"
              >
                {items.category}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </nav>

  );
})
